import { Loader } from 'src/components';
import { SaleInfo } from 'src/pages/PropertyDetails/PropertyDetails';
import { tHouseFull } from 'src/types';

import './SalesTab.scss';

interface IProps {
    loading: boolean;
    property?: tHouseFull;
}

export const SalesTab = ({ loading, property }: IProps) => {
    if (loading || property == null) {
        return (
            <div className="property-ledger-loader">
                <div className="ledger-loader">
                    <Loader />
                </div>
            </div>
        );
    }

    const saleInformation = property.sale_information != null ? property.sale_information : [];

    return (
        <div className="property-ledger-tab-container sales-transactions-tab">
            <SaleInfo
                sale_information={saleInformation}
                municipalityId={property.municipality_id}
            />
        </div>
    );
};
