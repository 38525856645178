import { SetStateAction } from 'react';
import { tInfo } from 'src/types';

export type tInputValidator = (newVal: string) => boolean;

export type tInputErrors = {
    [key: string]: string | undefined;
};
export const validateIsInteger = (newVal: string): boolean => {
    return /^\d+$/.test(newVal);
};

export interface INamedInputValidators {
    [key: string]: (newVal: string) => string | undefined;
}

export const updateNamedInputErrors = (
    validators: INamedInputValidators,
    updateInputErrors: (newInputErrorsState: SetStateAction<tInputErrors>) => void,
    searchInput: tInfo
): void => {
    if (validators[searchInput.searchFieldName] == null) {
        return;
    }
    updateInputErrors(prev => ({
        ...prev,
        [searchInput.searchFieldName]: validators[searchInput.searchFieldName](
            searchInput.searchInputValue
        ),
    }));
};
