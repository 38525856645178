import { useEffect } from 'react';

import { Footer, Loader } from 'src/components';
import { ORANGE_NJ_MUNICIPALITY_ID, OrganizationTypes } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    UserReducer,
    getMunicipalityAsync,
    getNotificationSettings,
    setSingleNotificationSetting,
} from 'src/store';

import { NotificationSettingsToggles } from './components';

import './NotificationSettings.scss';
import {
    userCanViewNotificationSetting,
    userCanViewOrganization,
    userOrganizationIsMunicipality,
} from 'src/helpers';
import { NotFound } from '../NotFound';

export const NotificationSettings = () => {
    const dispatch = useAppDispatch();
    const { user }: UserReducer = useAppSelector(state => state.userReducer);
    const { municipality } = useAppSelector(state => state.municipalitiesReducer);

    const { loading, notificationSettings } = useAppSelector(
        state => state.notificationSettingsReducer
    );

    // If a user is part of a municipal organization, load that municipality
    // If a user is a Balcony employee, load the notifications for Orange, NJ
    useEffect(() => {
        if (
            user?.balconyUser.organization_permissions?.organization_type ===
            OrganizationTypes.MUNICIPAL
        ) {
            const municipalityId = user.balconyUser.organization_permissions.organization_locality;
            dispatch(getMunicipalityAsync(municipalityId));
            dispatch(getNotificationSettings(municipalityId, user.thirdwebAccount));
            return;
        }

        if (user?.balconyUser.is_balcony_employee) {
            dispatch(getMunicipalityAsync(ORANGE_NJ_MUNICIPALITY_ID));
            dispatch(getNotificationSettings(ORANGE_NJ_MUNICIPALITY_ID, user.thirdwebAccount));
        }
    }, [user]);

    if (!userCanViewOrganization(user)) {
        return <NotFound />;
    }

    if (!user || loading || municipality == null) {
        return (
            <div className="notification-settings">
                <div className="notification-settings__content container notification-settings-loading">
                    <Loader />
                </div>
            </div>
        );
    }

    const activeNotificationTypes = notificationSettings
        ? notificationSettings.user_notification_types
        : [];

    const availableNotifications = activeNotificationTypes.filter(notificationSetting => {
        return userCanViewNotificationSetting(notificationSetting.notification_type_name, user);
    });

    const updateNotificationSetting = (
        e: React.ChangeEvent<HTMLInputElement>,
        notificationTypeName: string
    ): void => {
        dispatch(
            setSingleNotificationSetting(
                notificationTypeName,
                ORANGE_NJ_MUNICIPALITY_ID,
                e.target.checked,
                user.thirdwebAccount
            )
        );
    };

    const noAvailableNotifications = (
        <div>No notifications are available for your organization.</div>
    );

    const notificationTogglesContent = (
        <NotificationSettingsToggles
            notificationSettings={availableNotifications}
            onToggleChange={updateNotificationSetting}
        />
    );

    const notificationsContent =
        availableNotifications.length > 0 &&
        userOrganizationIsMunicipality(ORANGE_NJ_MUNICIPALITY_ID, user)
            ? notificationTogglesContent
            : noAvailableNotifications;

    return (
        <div className="notification-settings">
            <div className="notification-settings__content container">
                <div className="notification-settings-header">
                    <h1>Manage your Email notifications preferences below:</h1>
                </div>
                {notificationsContent}
            </div>
            <Footer />
        </div>
    );
};
