import { useState } from 'react';
import { ethers } from 'ethers';
import CONTRACT_ABI from './contractAbi.json';

interface BlockchainReaderOptions {
    contractAddress?: string;
    rpcUrl?: string;
}

interface BlockchainReaderState {
    loading: boolean;
    error: string | null;
}

export function useBlockchainReader(options: BlockchainReaderOptions = {}) {
    const [state, setState] = useState<BlockchainReaderState>({
        loading: false,
        error: null,
    });

    const rpcUrl = options.rpcUrl || 'https://subnets.avax.network/armada/testnet/rpc';

    const getContract = (contractAddress: string) => {
        const provider = new ethers.JsonRpcProvider(rpcUrl);
        return new ethers.Contract(contractAddress, CONTRACT_ABI, provider);
    };

    const readContract = async <T>(
        contractAddress: string,
        method: string,
        args: unknown[] = []
    ): Promise<T | null> => {
        if (!contractAddress) return null;

        setState(prev => ({ ...prev, loading: true, error: null }));

        try {
            const contract = getContract(contractAddress);
            const result = await contract[method](...args);
            return result as T;
        } catch (error) {
            console.error(`Error calling ${method}:`, error);
            setState(prev => ({
                ...prev,
                error: `Failed to read blockchain data: ${
                    error instanceof Error ? error.message : 'Unknown error occurred'
                }`,
            }));
            return null;
        } finally {
            setState(prev => ({ ...prev, loading: false }));
        }
    };

    const readPropertyData = async (contractAddress: string, tokenId: string | number) => {
        return readContract(contractAddress, 'getProperty', [tokenId]);
    };

    const readDocuments = async (contractAddress: string, tokenId: string | number) => {
        return readContract(contractAddress, 'getDocumentsOfProperty', [tokenId]);
    };

    const readDocumentType = async (contractAddress: string, documentTypeId: number) => {
        return readContract<string>(contractAddress, 'getDocumentType', [documentTypeId]);
    };

    const readDocumentVersionHistory = async (
        contractAddress: string,
        firstVersionTokenId: number
    ) => {
        return readContract<number[]>(contractAddress, 'getDocumentVersionHistory', [
            firstVersionTokenId,
        ]);
    };

    const readDocument = async (contractAddress: string, documentId: number) => {
        return readContract(contractAddress, 'getDocument', [documentId]);
    };

    return {
        ...state,
        readPropertyData,
        readDocuments,
        readDocumentType,
        readDocumentVersionHistory,
        readDocument,
    };
}
