import { Button, Container, Header, SpaceBetween, Grid } from '@cloudscape-design/components';
import { tHouseFull } from 'src/types';
import { tBlockchainProperty } from 'src/types/tBlockchain';
import { makeArmadaExplorerUrl, copyText } from 'src/helpers';
import { IconCopy, IconCheck } from 'src/icons';
import { useState } from 'react';
import { useBlockchainReader } from 'src/hooks';
import './BlockchainOverview.scss';

interface IProps {
    loading: boolean;
    property?: tHouseFull;
}

interface InfoRowProps {
    label: string;
    value: string;
    copyValue?: string;
    href?: string;
}

const InfoRow = ({ label, value, copyValue, href }: InfoRowProps) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        if (copyValue) {
            copyText(copyValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
        }
    };

    return (
        <div className="blockchain-overview__info-row">
            <div className="label">{label}</div>
            <div className="value-container">
                {href ? (
                    <a href={href} target="_blank" rel="noopener noreferrer" className="value link">
                        {value}
                    </a>
                ) : (
                    <span className="value">{value}</span>
                )}
                {copyValue && (
                    <button className="copy-button" onClick={handleCopy}>
                        {copied ? (
                            <IconCheck width={16} height={16} />
                        ) : (
                            <IconCopy width={16} height={16} />
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export const BlockchainOverview = ({ loading, property }: IProps) => {
    const [propertyData, setPropertyData] = useState<tBlockchainProperty | null>(null);
    const { loading: fetchingData, error, readPropertyData } = useBlockchainReader();

    const fetchPropertyData = async () => {
        if (!property?.contract_address || !property.token_id) return;

        const data = await readPropertyData(property.contract_address, property.token_id);
        if (data) {
            setPropertyData(data as tBlockchainProperty);
        }
    };

    if (!property) return null;

    return (
        <SpaceBetween size="l">
            <Container>
                <SpaceBetween size="m">
                    <InfoRow
                        label="Token ID"
                        value={`#${property.token_id}`}
                        href={property.smart_contract_uri}
                    />
                    {property.contract_address && (
                        <InfoRow
                            label="Contract Address"
                            value={property.contract_address}
                            copyValue={property.contract_address}
                            href={makeArmadaExplorerUrl(property.contract_address, 'address')}
                        />
                    )}
                    {property.hash && (
                        <InfoRow
                            label="Mint Transaction"
                            value={property.hash}
                            copyValue={property.hash}
                            href={makeArmadaExplorerUrl(property.hash, 'tx')}
                        />
                    )}
                </SpaceBetween>
            </Container>

            <Container
                header={
                    <Header variant="h2" description="View on-chain property details">
                        Property Details
                    </Header>
                }
            >
                {error ? (
                    <div className="blockchain-overview__error">
                        {error}
                        <Button onClick={() => fetchPropertyData()}>Try Again</Button>
                    </div>
                ) : propertyData ? (
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <SpaceBetween size="s">
                            <InfoRow label="Property ID" value={propertyData.propertyId} />
                            <InfoRow label="Street Address" value={propertyData.streetAddress} />
                            <InfoRow label="Block ID" value={propertyData.blockId} />
                            <InfoRow label="Lot" value={propertyData.lot} />
                            <InfoRow label="Municipality" value={propertyData.municipality} />
                            <InfoRow label="County" value={propertyData.county} />
                        </SpaceBetween>
                        <SpaceBetween size="s">
                            <InfoRow label="State" value={propertyData.state} />
                            <InfoRow label="Zip Code" value={propertyData.zipCode} />
                            <InfoRow label="Country" value={propertyData.country} />
                            <InfoRow
                                label="Location"
                                value={`${propertyData.latitude}, ${propertyData.longitude}`}
                                href={`https://www.google.com/maps?q=${encodeURIComponent(
                                    `${propertyData.streetAddress}, ${propertyData.municipality}, ${propertyData.state} ${propertyData.zipCode}`
                                )}&ll=${propertyData.latitude},${propertyData.longitude}`}
                            />
                            <InfoRow
                                label="Status"
                                value={propertyData.isActive ? 'Active' : 'Inactive'}
                            />
                        </SpaceBetween>
                    </Grid>
                ) : (
                    <Button
                        iconName="refresh"
                        onClick={fetchPropertyData}
                        loading={loading || fetchingData}
                    >
                        Fetch Property Details
                    </Button>
                )}
            </Container>
        </SpaceBetween>
    );
};
