import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';

import { updateReducerPaginationState } from 'src/helpers';

import {
    RENT_LEVELING_SET_LOADING,
    RENT_LEVELING_SET_PAGINATION,
    RENT_LEVELING_SET_QUERY,
    RENT_LEVELING_SET_RENT_LEVELED_PROPERTIES,
} from './action-types';
import { initialState } from './initial-state';
import { tRentLevelingAction, tRentLevelingReducerState } from './types';

const rentLevelingPersistConfig = {
    key: 'rentLeveling',
    storage: storage,
    whitelist: [],
};

export const rentLeveling = (
    state = initialState,
    action: tRentLevelingAction
): tRentLevelingReducerState => {
    switch (action.type) {
        case RENT_LEVELING_SET_RENT_LEVELED_PROPERTIES: {
            return {
                ...initialState,
                loading: action.payload.loading,
                rentLeveledProperties:
                    action.payload.query.skip === 0
                        ? action.payload.rentLeveledProperties
                        : [
                              ...state.rentLeveledProperties,
                              ...(action.payload.rentLeveledProperties || []),
                          ],
                summary: action.payload.summary,
                query: action.payload.query,
                pagination: updateReducerPaginationState(
                    state.pagination,
                    action.payload.pagination
                ),
            };
        }
        case RENT_LEVELING_SET_LOADING: {
            return { ...state, loading: action.payload.loading };
        }
        case RENT_LEVELING_SET_PAGINATION: {
            return {
                ...state,
                pagination: updateReducerPaginationState(
                    state.pagination,
                    action.payload.pagination
                ),
            };
        }
        case RENT_LEVELING_SET_QUERY: {
            return { ...state, query: action.payload.query };
        }
    }

    return state;
};

export const rentLevelingReducer = persistReducer<tRentLevelingReducerState, tRentLevelingAction>(
    rentLevelingPersistConfig,
    rentLeveling
);
