import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initialState } from './initialState';
import {
    NOTIFICATION_SETTINGS_UPDATE_NOTIFICATION_SETTINGS_LOADING,
    NOTIFICATION_SETTINGS_UPDATE_SINGLE_NOTIFICATION_SETTING,
    NOTIFICATION_SETTINGS_UPDATE_USER_NOTIFICATION_SETTINGS,
} from './action-types';
import { NotificationSettingsReducer, tNotificationSettingsAction } from './types';

const notificationSettingsPersistConfig = {
    key: 'notificationSettingsState',
    storage: storage,
    whitelist: [],
};

const notificationSettings = (
    state = initialState,
    { payload, type }: tNotificationSettingsAction
): NotificationSettingsReducer => {
    switch (type) {
        case NOTIFICATION_SETTINGS_UPDATE_NOTIFICATION_SETTINGS_LOADING: {
            return {
                ...state,
                loading: payload.loading,
            };
        }
        case NOTIFICATION_SETTINGS_UPDATE_USER_NOTIFICATION_SETTINGS: {
            return {
                ...state,
                notificationSettings: payload.notificationSettings,
                loading: false,
            };
        }
        case NOTIFICATION_SETTINGS_UPDATE_SINGLE_NOTIFICATION_SETTING: {
            if (state.notificationSettings == null) {
                return state;
            }
            const newNotificationTypes = state.notificationSettings.user_notification_types.map(
                setting => {
                    if (setting.notification_type_name === payload.notificationTypeName) {
                        return {
                            notification_type_name: payload.notificationTypeName,
                            is_subscribed: payload.isSubscribed,
                        };
                    }
                    return setting;
                }
            );
            return {
                ...state,
                notificationSettings: {
                    ...state.notificationSettings,
                    user_notification_types: newNotificationTypes,
                },
            };
        }
        default:
            return state;
    }
};

export const notificationSettingsReducer = persistReducer<
    NotificationSettingsReducer,
    tNotificationSettingsAction
>(notificationSettingsPersistConfig, notificationSettings);
