import './SearchHeaderLine.scss';

import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MainButton } from '../Buttons';
import { SearchAdditionLine } from '../SearchAdditionLine';

import { Loader } from 'src/components';
import {
    filterBySubstring,
    getOccupancyStatusFromSearchParams,
    INamedInputValidators,
    setOccupancyStatusInSearchParams,
    tInputErrors,
    updateNamedInputErrors,
    validateIsInteger,
} from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { IconSearch } from 'src/icons';
import { getHousesAsync } from 'src/store';
import { tInfo, tSearchData } from 'src/types';
import { MainInputDropdown } from '../Inputs/MainInputDropdown';

interface IProps {
    slug?: string | null;
    query: tSearchData;
    dataLoading?: boolean;
}

export const SearchHeaderLine: FC<IProps> = ({ slug, query }) => {
    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const validators: INamedInputValidators = {
        block: (newVal: string): string | undefined => {
            if (validateIsInteger(newVal)) {
                return undefined;
            }
            return 'Block must be a number';
        },
    };

    const [isHide, setIsHide] = useState<boolean>(true);
    const [isNewData, setIsNewData] = useState<boolean>(false);
    const [data, setData] = useState<tSearchData>(query);
    const [fullLoading, setFullLoading] = useState<boolean>(false);
    const [inputErrors, setInputErrors] = useState<tInputErrors>({});

    const { addressesForAutocomplete }: { addressesForAutocomplete: string[] } = useAppSelector(
        state => state.housesReducer
    );

    // Address filtering, as currently implemented, works as follows:
    // When the SearchResults page is first rendered, we call the server
    // to load all addresses (or at least the first 10,000) from the asset-server.
    // That list is made unique, alphabetized, and stored in the state.
    // If the address a user has typed in is 3 characters or more, then remove
    // all addresses that do not include that string.
    // As this list can be large, we truncate it below (currently to 50)
    // when displaying the list of matching addresses.
    const selectableAddresses =
        data.address.length > 2
            ? filterBySubstring(data.address, addressesForAutocomplete)
            : addressesForAutocomplete;

    const checkData = () => {
        setIsNewData(
            query.address !== data.address ||
                query.block !== data.block ||
                query.lot !== data.lot ||
                query.owner_name !== data.owner_name ||
                query.qualifier !== data.qualifier ||
                query.tax !== data.tax ||
                query.occupancy !== data.occupancy
        );
    };
    const getHouses = async () => {
        if (isNewData && slug) {
            setFullLoading(true);
            await dispatch(
                getHousesAsync({
                    ...data,
                    municipality_slug: slug,
                })
            );
            setFullLoading(false);

            const occupancyStatusFromQuery = getOccupancyStatusFromSearchParams(searchParams);
            if (occupancyStatusFromQuery !== data.occupancy) {
                setOccupancyStatusInSearchParams(data.occupancy, setSearchParams);
            }
        }
    };
    useEffect(() => {
        setData({ ...query });
    }, [query]);

    useEffect(() => {
        checkData();
    }, [data]);

    const isInputValid = Object.values(inputErrors).every(error => error === undefined);

    return (
        <div className="search-header">
            <div className="search-header__content container">
                <div className="search-header__content_small">
                    <MainInputDropdown
                        items={selectableAddresses.slice(0, 50)}
                        value={data.address}
                        placeholder="Search an address"
                        className="search-address"
                        label="Address"
                        onSelect={(val: string) => {
                            setData(prev => ({ ...prev, address: val }));
                        }}
                    />
                </div>
                <div
                    className={`search-header__content_big search-header__content_big--${
                        isHide ? 'hide' : 'show'
                    }`}
                >
                    <SearchAdditionLine
                        {...data}
                        inputErrors={inputErrors}
                        onChangeInfo={(searchInput: tInfo) => {
                            setData(prev => ({
                                ...prev,
                                [searchInput.searchFieldName]: searchInput.searchInputValue,
                            }));
                            updateNamedInputErrors(validators, setInputErrors, searchInput);
                        }}
                    />
                </div>
                <div className="search-header__content_buttons">
                    <MainButton
                        buttonType="icon"
                        onClick={() => getHouses()}
                        disabled={fullLoading || !isNewData || !isInputValid}
                    >
                        {fullLoading ? (
                            <Loader color="white" />
                        ) : (
                            <>
                                <span className="text">Search</span>
                                <IconSearch />
                            </>
                        )}
                    </MainButton>
                    <MainButton
                        buttonType="primary"
                        buttonColor="white"
                        onClick={() => setIsHide(!isHide)}
                    >
                        {isHide ? 'Show More' : 'Hide'}
                    </MainButton>
                </div>
            </div>
        </div>
    );
};
