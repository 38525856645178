import { useState, useEffect } from 'react';
import {
    Table,
    Box,
    Button,
    Header,
    SpaceBetween,
    Container,
    Link,
} from '@cloudscape-design/components';
import {
    tHouseFull,
    tBlockchainDocument,
    tBlockchainDocumentVerification,
    tUserUploadedPropertyDocument,
} from 'src/types';
import { useBlockchainReader } from 'src/hooks';
import { truncateHash, formatTimestamp, copyText } from 'src/helpers';
import { IconCopy, IconCheck } from 'src/icons';
import { VerificationModal } from './VerificationModal';
import { DocumentHistoryModal } from './DocumentHistoryModal';
import './DocumentsOverview.scss';

const verifyDocumentMatch = (
    blockchainDocument: tBlockchainDocument,
    dbDocument: tUserUploadedPropertyDocument
): tBlockchainDocumentVerification => {
    const isValid =
        dbDocument.hash_digest === blockchainDocument.hashDigest &&
        dbDocument.file_name.replace(/\.[^.]+$/, '') ===
            blockchainDocument.fileName.replace(/\.[^.]+$/, '') &&
        dbDocument.size_in_bytes === blockchainDocument.sizeInBytes;

    return {
        lastVerified: Date.now(),
        status: isValid ? 'VERIFIED' : 'MISMATCH',
        errorMessage: isValid
            ? undefined
            : 'Document data mismatch between database and blockchain',
        onChainDocument: blockchainDocument,
        databaseDocument: {
            hashDigest: dbDocument.hash_digest,
            fileName: dbDocument.file_name,
            sizeInBytes: dbDocument.size_in_bytes,
            signatureTimestamp: new Date(dbDocument.created_at).getTime(),
        },
    };
};

type BlockchainDocumentData = [
    number,
    number,
    string,
    bigint,
    number,
    string,
    string,
    string,
    number,
    number,
    number
];

const processDocumentsData = (
    docs: Record<string, BlockchainDocumentData>
): tBlockchainDocument[] => {
    return Object.entries(docs)
        .filter(([key]) => !isNaN(Number(key)) && key !== 'length')
        .map(([, doc]) => ({
            propertyTokenId: Number(doc[0]),
            documentTypeId: Number(doc[1]),
            hashDigest: doc[2],
            signatureTimestamp: doc[3],
            signatureVersion: Number(doc[4]),
            signature: doc[5],
            uploadedBy: doc[6],
            fileName: doc[7],
            sizeInBytes: Number(doc[8]),
            previousVersionTokenId: Number(doc[9]),
            firstVersionTokenId: Number(doc[10]),
        }));
};

interface IProps {
    loading: boolean;
    property?: tHouseFull;
    user_uploaded_property_documents?: tUserUploadedPropertyDocument[];
    navigateToDocument: (documentId: string) => void;
}

export const DocumentsOverview = ({
    loading,
    property,
    user_uploaded_property_documents,
    navigateToDocument,
}: IProps) => {
    const [documents, setDocuments] = useState<tBlockchainDocument[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<tBlockchainDocument | null>(null);
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [versionHistory, setVersionHistory] = useState<tBlockchainDocument[]>([]);
    const [documentTypes, setDocumentTypes] = useState<Record<number, string>>({});
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [verifyingDocument, setVerifyingDocument] = useState<tBlockchainDocument | null>(null);
    const [verificationResult, setVerificationResult] =
        useState<tBlockchainDocumentVerification | null>(null);
    const [copiedHash, setCopiedHash] = useState<string | null>(null);

    const {
        loading: fetchingData,
        error,
        readDocuments,
        readDocumentType,
        readDocumentVersionHistory,
        readDocument,
    } = useBlockchainReader();

    const fetchDocuments = async () => {
        if (!property?.token_id) return;
        if (!property.contract_address) return;

        const docs = await readDocuments(property.contract_address, property.token_id);

        if (docs && Object.keys(docs).length > 0) {
            const validDocs = processDocumentsData(docs as Record<string, BlockchainDocumentData>);
            setDocuments(validDocs);

            const types: Record<number, string> = {};
            for (const doc of validDocs) {
                if (!types[doc.documentTypeId]) {
                    const type = await readDocumentType(
                        property.contract_address,
                        doc.documentTypeId
                    );
                    if (type) {
                        types[doc.documentTypeId] = type;
                    }
                }
            }
            setDocumentTypes(types);
        }
    };

    const fetchVersionHistory = async (document: tBlockchainDocument) => {
        if (!property?.contract_address) return;

        const versionIds = await readDocumentVersionHistory(
            property.contract_address,
            document.firstVersionTokenId
        );

        if (!versionIds || !Array.isArray(versionIds)) return;

        const versions: tBlockchainDocument[] = [];
        for (const id of versionIds) {
            const version = await readDocument(property.contract_address, id);
            if (version && typeof version === 'object') {
                versions.push(version as tBlockchainDocument);
            }
        }
        setVersionHistory(versions);
    };

    useEffect(() => {
        fetchDocuments();
    }, [property?.token_id]);

    const handleVersionHistoryClick = async (document: tBlockchainDocument) => {
        setSelectedDocument(document);
        await fetchVersionHistory(document);
        setShowVersionHistory(true);
    };

    const handleCopyHash = (hash: string) => {
        copyText(hash);
        setCopiedHash(hash);
        setTimeout(() => setCopiedHash(null), 1500);
    };

    const verifyDocumentIntegrity = async (document: tBlockchainDocument) => {
        if (!property?.contract_address || !user_uploaded_property_documents) return;

        setVerifyingDocument(document);
        setShowVerificationModal(true);

        try {
            const dbDocument = user_uploaded_property_documents.find(
                doc => doc.hash_digest === document.hashDigest
            );

            if (!dbDocument) {
                setVerificationResult({
                    lastVerified: Date.now(),
                    status: 'ERROR',
                    errorMessage: 'Document not found in database',
                });
                return;
            }

            setVerificationResult(verifyDocumentMatch(document, dbDocument));
        } catch (error) {
            setVerificationResult({
                lastVerified: Date.now(),
                status: 'ERROR',
                errorMessage: error instanceof Error ? error.message : 'Unknown error occurred',
            });
        }
    };

    const handleDismissVerification = () => {
        setShowVerificationModal(false);
        setVerifyingDocument(null);
        setVerificationResult(null);
    };

    const columnDefinitions = [
        {
            id: 'fileName',
            header: 'Document Name',
            cell: (item: tBlockchainDocument) => (
                <Link onFollow={() => navigateToDocument(item.hashDigest)}>
                    <div className="documents-overview__file-name">{item.fileName}</div>
                </Link>
            ),
            sortingField: 'fileName',
        },
        {
            id: 'type',
            header: 'Type',
            cell: (item: tBlockchainDocument) => (
                <span className="documents-overview__document-type">
                    {documentTypes[item.documentTypeId] || 'Unknown'}
                </span>
            ),
            sortingField: 'type',
        },
        {
            id: 'uploadDate',
            header: 'Upload Date',
            cell: (item: tBlockchainDocument) => (
                <div className="documents-overview__date">
                    {formatTimestamp(item.signatureTimestamp)}
                </div>
            ),
            sortingField: 'uploadDate',
        },
        {
            id: 'version',
            header: 'Version',
            cell: (item: tBlockchainDocument) => (
                <Link onFollow={() => handleVersionHistoryClick(item)}>
                    {BigInt(item.signatureVersion).toString()}
                </Link>
            ),
        },
        {
            id: 'hash',
            header: 'Hash',
            cell: (item: tBlockchainDocument) => (
                <div className="documents-overview__hash">
                    <span className="documents-overview__hash-text">
                        {truncateHash(item.hashDigest)}
                    </span>
                    <button className="copy-button" onClick={() => handleCopyHash(item.hashDigest)}>
                        {copiedHash === item.hashDigest ? (
                            <IconCheck width={16} height={16} />
                        ) : (
                            <IconCopy width={16} height={16} />
                        )}
                    </button>
                </div>
            ),
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: (item: tBlockchainDocument) => (
                <Button
                    onClick={() => verifyDocumentIntegrity(item)}
                    iconName="external"
                    className="documents-overview__verify-button"
                >
                    Verify Integrity
                </Button>
            ),
        },
    ];

    if (!property) return null;

    if (error) {
        return (
            <Container>
                <div className="documents-overview__error">
                    {error}
                    <Button onClick={fetchDocuments}>Try Again</Button>
                </div>
            </Container>
        );
    }

    return (
        <Container>
            <SpaceBetween size="l">
                <Table
                    loading={loading || fetchingData}
                    loadingText="Loading documents"
                    items={documents}
                    columnDefinitions={columnDefinitions}
                    variant="full-page"
                    stickyHeader
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No documents</b>
                            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                                No documents have been uploaded for this property.
                            </Box>
                        </Box>
                    }
                    header={
                        <Header
                            variant="h2"
                            description="View and verify property documents stored on the blockchain"
                            counter={`(${documents.length})`}
                        >
                            Associated Documents
                        </Header>
                    }
                />

                <DocumentHistoryModal
                    visible={showVersionHistory}
                    selectedDocument={selectedDocument}
                    versionHistory={versionHistory}
                    onDismiss={() => setShowVersionHistory(false)}
                />

                <VerificationModal
                    visible={showVerificationModal}
                    document={verifyingDocument}
                    verificationResult={verificationResult}
                    onDismiss={handleDismissVerification}
                    onVerify={verifyDocumentIntegrity}
                />
            </SpaceBetween>
        </Container>
    );
};
