import { useState } from 'react';
import { MessageType, displayMessage, dismissMessage } from 'src/store';
import { useAppDispatch, useTimeout } from 'src/hooks';

interface UseDocumentHighlightProps {
    documentsLoading: boolean;
}

export function useDocumentHighlight({ documentsLoading }: UseDocumentHighlightProps) {
    const dispatch = useAppDispatch();
    const [pendingDocumentId, setPendingDocumentId] = useState<string | null>(null);

    useTimeout(
        () => {
            if (pendingDocumentId && !documentsLoading) {
                let element = document.getElementById(`document-${pendingDocumentId}`);
                if (!element) {
                    element = document.getElementById(
                        `document-${encodeURIComponent(pendingDocumentId)}`
                    );
                }
                if (element) {
                    const cardElement = element.closest('li[class*="awsui_card_"]');
                    element.scrollIntoView({ behavior: 'smooth' });
                    if (cardElement) {
                        cardElement.classList.add('highlight-card');
                        setTimeout(() => cardElement.classList.remove('highlight-card'), 4000);
                    }
                } else {
                    dispatch(
                        displayMessage({
                            title: 'Document Not Found',
                            content:
                                'This document exists on the blockchain but has been deleted from the system.',
                            messageType: MessageType.Warning,
                            onDismiss: (id: number) => dispatch(dismissMessage(id)),
                            durationSeconds: 10,
                        })
                    );
                }
                setPendingDocumentId(null);
            }
        },
        !documentsLoading && pendingDocumentId ? 0 : null,
        pendingDocumentId || undefined
    );

    return {
        setPendingDocumentId,
        pendingDocumentId,
    };
}
