import { Tabs } from '@cloudscape-design/components';
import { tHouseFull, tUserUploadedPropertyDocument } from 'src/types';
import { BlockchainOverview, DocumentsOverview } from './Subtabs';
import './BlockchainTab.scss';

interface IProps {
    loading: boolean;
    property?: tHouseFull;
    documents_loading: boolean;
    user_uploaded_property_documents?: tUserUploadedPropertyDocument[];
    navigateToDocument: (documentId: string) => void;
}

// const PropertyHistory = () => {
//     return <div>Property History content</div>;
// };

// const Verification = () => {
//     return <div>Verification content</div>;
// };

export const BlockchainTab = ({
    loading,
    property,
    documents_loading,
    user_uploaded_property_documents,
    navigateToDocument,
}: IProps) => {
    return (
        <div className="blockchain-tab">
            <h1 className="blockchain-tab__title">Blockchain Data</h1>
            <p className="blockchain-tab__description">
                Balcony utilizes blockchain technology to store and verify property data. This page
                allows you to view and verify blockchain information for this property.
            </p>
            <Tabs
                tabs={[
                    {
                        label: 'Overview',
                        id: 'overview',
                        content: <BlockchainOverview loading={loading} property={property} />,
                    },
                    {
                        label: 'Documents',
                        id: 'documents',
                        content: (
                            <DocumentsOverview
                                loading={documents_loading}
                                property={property}
                                user_uploaded_property_documents={user_uploaded_property_documents}
                                navigateToDocument={navigateToDocument}
                            />
                        ),
                    },
                    // {
                    //     label: 'Property History',
                    //     id: 'property-history',
                    //     content: <PropertyHistory />,
                    // },
                    // {
                    //     label: 'Verification',
                    //     id: 'verification',
                    //     content: <Verification />,
                    // },
                ]}
            />
        </div>
    );
};
