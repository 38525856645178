import { Dispatch } from 'redux';
import { Account } from 'thirdweb/wallets';

import { assetApiClient, makeSignedGetRequest } from 'src/api';
import { tSearchPagination } from 'src/types';
import { assembleQueryString } from 'src/store/houses';
import {
    RENT_LEVELING_SET_LOADING,
    RENT_LEVELING_SET_PAGINATION,
    RENT_LEVELING_SET_QUERY,
    RENT_LEVELING_SET_RENT_LEVELED_PROPERTIES,
} from './action-types';
import {
    tGetRentLeveledPropertiesResponse,
    tRentLeveledProperty,
    tRentLevelingQuery,
    tRentLevelingSummary,
    tSetRentLeveledProperties,
    tSetRentLeveledPropertiesLoading,
    tSetRentLeveledPropertiesPagination,
    tSetRentLevelingQuery,
} from './types';

const setRentLeveledProperties = (
    rentLeveledProperties: tRentLeveledProperty[],
    summary: tRentLevelingSummary,
    loading: boolean,
    query: tRentLevelingQuery,
    paginationUpdate?: tSearchPagination
): tSetRentLeveledProperties => {
    return {
        type: RENT_LEVELING_SET_RENT_LEVELED_PROPERTIES,
        payload: {
            loading,
            summary,
            rentLeveledProperties,
            query,
            pagination: paginationUpdate,
        },
    };
};

const setRentLeveledPropertiesLoading = (loading: boolean): tSetRentLeveledPropertiesLoading => {
    return {
        type: RENT_LEVELING_SET_LOADING,
        payload: {
            loading,
        },
    };
};

export const changeRentLevelingPagination = (
    paginationUpdate: tSearchPagination
): tSetRentLeveledPropertiesPagination => {
    return {
        type: RENT_LEVELING_SET_PAGINATION,
        payload: {
            pagination: paginationUpdate,
        },
    };
};

const changeRentLevelingQuery = (query: tRentLevelingQuery): tSetRentLevelingQuery => {
    return {
        type: RENT_LEVELING_SET_QUERY,
        payload: {
            query,
        },
    };
};

export const getRentLeveledPropertiesAsync =
    (
        municipalityId: string,
        thirdwebAccount: Account,
        query: tRentLevelingQuery,
        paginationUpdate?: tSearchPagination
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(setRentLeveledPropertiesLoading(true));
        dispatch(changeRentLevelingQuery(query));
        try {
            const { data }: { data: tGetRentLeveledPropertiesResponse } =
                await makeSignedGetRequest(
                    assetApiClient,
                    thirdwebAccount,
                    `/rent-leveling/${municipalityId}?${assembleQueryString(query)}`
                );
            const summary: tRentLevelingSummary = {
                propertyCount: data.property_count,
                rentLeveledPropertyCount: data.rent_leveled_property_count,
                rentLeveledUnitAnnualRevenueEstimate:
                    data.rent_leveled_unit_annual_revenue_estimate,
                rentLeveledUnitCount: data.rent_leveled_unit_count,
                rentLeveledUnitAmountPaid: data.rent_leveled_unit_amount_paid,
                rentLeveledUnitMissingRevenue: data.rent_leveled_unit_missing_revenue,
                rentLeveledUnitRegistrationPaymentComplianceRate:
                    data.rent_leveled_unit_registration_payment_compliance_rate,
            };
            dispatch(
                setRentLeveledProperties(
                    data.rent_leveled_properties || [],
                    summary,
                    false,
                    query,
                    paginationUpdate
                )
            );
        } catch (e) {
            dispatch(setRentLeveledPropertiesLoading(false));
            console.error(e);
        }
    };
