import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Footer, ListViewTable, ToplineTable } from 'src/components';
import { AccessLevels, RENT_LEVELING_MODULE_ID } from 'src/constants';
import { dollarFormat, thousandFormat, userHasModulePermission } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { NotFound } from 'src/pages';
import {
    changeRentLevelingPagination,
    getMunicipalityAsync,
    getRentLeveledPropertiesAsync,
} from 'src/store';
import { tSearchPagination } from 'src/types';

import { makeRentLevelingTableColumns, rentLevelingTableRowRenderer } from './components';

import './RentLevelingSummary.scss';
import { tRentLevelingQuery } from 'src/store';

export const RentLevelingSummary = () => {
    const { slug } = useParams();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.userReducer);
    const { loading, pagination, summary, rentLeveledProperties, query } = useAppSelector(
        state => state.rentLevelingReducer
    );

    const {
        propertyCount,
        rentLeveledPropertyCount,
        rentLeveledUnitCount,
        rentLeveledUnitAnnualRevenueEstimate,
        rentLeveledUnitAmountPaid,
        rentLeveledUnitMissingRevenue,
        rentLeveledUnitRegistrationPaymentComplianceRate,
    } = summary;

    useEffect(() => {
        if (slug) {
            dispatch(getMunicipalityAsync(slug));
            if (user != null) {
                dispatch(getRentLeveledPropertiesAsync(slug, user.thirdwebAccount, query));
            }
        }
    }, [slug]);

    if (
        user == null ||
        !userHasModulePermission(RENT_LEVELING_MODULE_ID, AccessLevels.READONLY, user)
    ) {
        return <NotFound />;
    }

    if (slug == null) {
        return <NotFound />;
    }
    const rentLevelingTableColumns = useMemo(() => makeRentLevelingTableColumns(slug), [slug]);

    const updatePaginationState = (paginationUpdate: tSearchPagination) => {
        dispatch(changeRentLevelingPagination(paginationUpdate));
    };

    const fetchMoreData = (query: tRentLevelingQuery, paginationUpdate?: tSearchPagination) => {
        dispatch(
            getRentLeveledPropertiesAsync(slug, user.thirdwebAccount, query, paginationUpdate)
        );
    };

    const { pageIndex, pageSize } = pagination;

    return (
        <div className="app__page rent-leveling-summary-page bg-grey">
            <div className="rent-leveling-summary">
                <div className="rent-leveling-summary__header">
                    <div className="rent-leveling-summary__rent-leveling-header container">
                        <h1>Rent Leveling</h1>
                    </div>
                </div>
                <div className="rent-leveling-summary-body-wrapper">
                    <ToplineTable
                        gridItems={[
                            {
                                title: 'Properties',
                                value: propertyCount != null ? thousandFormat(propertyCount) : '-',
                            },
                            {
                                title: 'Rent Leveled Properties',
                                value:
                                    rentLeveledPropertyCount != null
                                        ? thousandFormat(rentLeveledPropertyCount)
                                        : '-',
                            },
                            {
                                title: 'Rent Leveled Units',
                                value:
                                    rentLeveledUnitCount != null
                                        ? thousandFormat(rentLeveledUnitCount)
                                        : '-',
                            },
                            {
                                title: 'Rent Leveled Unit Annual Revenue Estimate',
                                value:
                                    rentLeveledUnitAnnualRevenueEstimate != null
                                        ? `$${dollarFormat(rentLeveledUnitAnnualRevenueEstimate)}`
                                        : '-',
                            },
                            {
                                title: 'Rent Leveled Unit Amount Paid',
                                value:
                                    rentLeveledUnitAmountPaid != null
                                        ? `$${dollarFormat(rentLeveledUnitAmountPaid)}`
                                        : '-',
                            },
                            {
                                title: 'Rent Leveled Unit Missing Revenue',
                                value:
                                    rentLeveledUnitMissingRevenue != null
                                        ? `$${dollarFormat(rentLeveledUnitMissingRevenue)}`
                                        : '-',
                            },
                            {
                                title: 'Rent Leveled Unit Registration Payment Compliance Rate',
                                value: rentLeveledUnitRegistrationPaymentComplianceRate
                                    ? rentLeveledUnitRegistrationPaymentComplianceRate
                                    : '-',
                            },
                        ]}
                    />
                    <div className="rent-leveling-summary-table-container container">
                        <div className="module-list-view-table-container">
                            <ListViewTable
                                municipality_slug={slug}
                                items={rentLeveledProperties}
                                loading={loading}
                                pageIndex={pageIndex}
                                pageSize={pageSize}
                                updatePagination={updatePaginationState}
                                resultsCount={rentLeveledPropertyCount}
                                queryParameters={query}
                                columnsDefs={rentLevelingTableColumns}
                                fetchMoreData={fetchMoreData}
                                rowRenderer={rentLevelingTableRowRenderer}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
