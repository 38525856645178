import {
    Box,
    Button,
    Header,
    SpaceBetween,
    Modal,
    StatusIndicator,
    Container,
    Alert,
} from '@cloudscape-design/components';
import { formatTimestamp } from 'src/helpers';
import { tBlockchainDocument, tBlockchainDocumentVerification } from 'src/types/tBlockchain';

interface VerificationModalProps {
    visible: boolean;
    document: tBlockchainDocument | null;
    verificationResult: tBlockchainDocumentVerification | null;
    onDismiss: () => void;
    onVerify: (document: tBlockchainDocument) => void;
}

const renderVerificationField = (label: string, storedValue: string, onChainValue: string) => {
    const matches = storedValue === onChainValue;
    return (
        <Box margin={{ bottom: 's' }}>
            <Box variant="awsui-key-label">{label}</Box>
            <SpaceBetween size="xs">
                <div>
                    <Box variant="small">Stored Value:</Box>
                    <span className="documents-overview__verification-value">{storedValue}</span>
                </div>
                <div>
                    <Box variant="small">On-Chain Value:</Box>
                    <span className="documents-overview__verification-value">{onChainValue}</span>
                </div>
                <StatusIndicator type={matches ? 'success' : 'error'}>
                    {matches ? 'Match' : 'Mismatch'}
                </StatusIndicator>
            </SpaceBetween>
        </Box>
    );
};

export const VerificationModal = ({
    visible,
    document,
    verificationResult,
    onDismiss,
    onVerify,
}: VerificationModalProps) => {
    if (!document) return null;

    return (
        <Modal
            onDismiss={onDismiss}
            visible={visible}
            header="Document Integrity Verification"
            size="large"
        >
            <SpaceBetween size="l">
                <Alert type="info">
                    This verification process checks if the document information stored in our
                    system matches what is recorded on the blockchain. Any discrepancies could
                    indicate unauthorized changes or data corruption.
                </Alert>

                {!verificationResult && (
                    <Box textAlign="center">
                        <SpaceBetween size="xs">
                            <StatusIndicator type="in-progress">
                                Verifying document integrity...
                            </StatusIndicator>
                            <Box variant="p" color="text-body-secondary">
                                Checking blockchain records for {document.fileName}
                            </Box>
                        </SpaceBetween>
                    </Box>
                )}

                {verificationResult?.status === 'ERROR' && (
                    <Alert type="error" header="Verification Failed">
                        {verificationResult.errorMessage}
                    </Alert>
                )}

                {verificationResult?.status === 'VERIFIED' && (
                    <Alert type="success" header="Verification Successful">
                        All document information matches the blockchain record.
                    </Alert>
                )}

                {verificationResult?.onChainDocument && (
                    <Container header={<Header variant="h3">Document Comparison</Header>}>
                        <SpaceBetween size="l">
                            {renderVerificationField(
                                'Document Hash',
                                document.hashDigest,
                                verificationResult.onChainDocument.hashDigest
                            )}
                            {renderVerificationField(
                                'File Name',
                                document.fileName,
                                verificationResult.onChainDocument.fileName
                            )}
                            {renderVerificationField(
                                'Size',
                                document.sizeInBytes.toString(),
                                verificationResult.onChainDocument.sizeInBytes.toString()
                            )}
                            {renderVerificationField(
                                'Upload Timestamp',
                                formatTimestamp(document.signatureTimestamp),
                                formatTimestamp(
                                    verificationResult.onChainDocument.signatureTimestamp
                                )
                            )}
                            {renderVerificationField(
                                'Uploaded By',
                                document.uploadedBy,
                                verificationResult.onChainDocument.uploadedBy
                            )}
                        </SpaceBetween>
                    </Container>
                )}

                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={onDismiss}>
                            Close
                        </Button>
                        {verificationResult && (
                            <Button variant="primary" onClick={() => onVerify(document)}>
                                Verify Again
                            </Button>
                        )}
                    </SpaceBetween>
                </Box>
            </SpaceBetween>
        </Modal>
    );
};
