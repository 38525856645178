import {
    Box,
    Button,
    SpaceBetween,
    Modal,
    StatusIndicator,
    ColumnLayout,
    Alert,
} from '@cloudscape-design/components';
import { tBlockchainDocument } from 'src/types';
import { truncateHash, formatTimestamp, copyText } from 'src/helpers';
import { IconCopy, IconCheck } from 'src/icons';
import { useState } from 'react';

interface TimelineItemProps {
    version: tBlockchainDocument;
    index: number;
    totalVersions: number;
    copiedHash: string | null;
    onCopyHash: (hash: string) => void;
}

const TimelineItem = ({
    version,
    index,
    totalVersions,
    copiedHash,
    onCopyHash,
}: TimelineItemProps) => (
    <div className="documents-overview__timeline-item">
        <div className="documents-overview__timeline-connector">
            {index !== totalVersions - 1 && <div className="documents-overview__timeline-line" />}
            <div className="documents-overview__timeline-dot" />
        </div>
        <Box padding="s" className="documents-overview__version-history-item">
            <SpaceBetween size="s">
                <div className="documents-overview__version-header">
                    <StatusIndicator type={index === 0 ? 'success' : 'info'}>
                        Version {version.signatureVersion}
                        {index === 0 ? ' (Latest)' : ''}
                    </StatusIndicator>
                    <div className="documents-overview__version-date">
                        {formatTimestamp(version.signatureTimestamp)}
                    </div>
                </div>
                <ColumnLayout columns={2} variant="text-grid">
                    <div>
                        <Box variant="awsui-key-label">Hash</Box>
                        <div className="documents-overview__hash">
                            <span className="documents-overview__hash-text">
                                {truncateHash(version.hashDigest)}
                            </span>
                            <button
                                className="copy-button"
                                onClick={() => onCopyHash(version.hashDigest)}
                            >
                                {copiedHash === version.hashDigest ? (
                                    <IconCheck width={16} height={16} />
                                ) : (
                                    <IconCopy width={16} height={16} />
                                )}
                            </button>
                        </div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Uploaded By</Box>
                        <div className="documents-overview__hash">
                            <span className="documents-overview__hash-text">
                                {truncateHash(version.uploadedBy)}
                            </span>
                            <button
                                className="copy-button"
                                onClick={() => onCopyHash(version.uploadedBy)}
                            >
                                {copiedHash === version.uploadedBy ? (
                                    <IconCheck width={16} height={16} />
                                ) : (
                                    <IconCopy width={16} height={16} />
                                )}
                            </button>
                        </div>
                    </div>
                </ColumnLayout>
                <Button
                    onClick={() => window.open(`ipfs://${version.hashDigest}`)}
                    iconName="external"
                >
                    View Metadata
                </Button>
            </SpaceBetween>
        </Box>
    </div>
);

interface DocumentHistoryModalProps {
    visible: boolean;
    selectedDocument: tBlockchainDocument | null;
    versionHistory: tBlockchainDocument[];
    onDismiss: () => void;
}

export const DocumentHistoryModal = ({
    visible,
    selectedDocument,
    versionHistory,
    onDismiss,
}: DocumentHistoryModalProps) => {
    const [copiedHash, setCopiedHash] = useState<string | null>(null);

    const handleCopyHash = (hash: string) => {
        copyText(hash);
        setCopiedHash(hash);
        setTimeout(() => setCopiedHash(null), 1500);
    };

    if (!selectedDocument) return null;

    return (
        <Modal
            onDismiss={onDismiss}
            visible={visible}
            header={`Version History - ${selectedDocument.fileName}`}
            size="large"
        >
            <SpaceBetween size="l">
                <Alert type="info">
                    Document version history is immutably stored on the blockchain. Each version
                    represents a unique state of the document with its own hash and metadata.
                </Alert>

                <div className="documents-overview__timeline">
                    {versionHistory.map((version, index) => (
                        <TimelineItem
                            key={version.hashDigest}
                            version={version}
                            index={index}
                            totalVersions={versionHistory.length}
                            copiedHash={copiedHash}
                            onCopyHash={handleCopyHash}
                        />
                    ))}
                </div>
            </SpaceBetween>
        </Modal>
    );
};
