import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { PropertyUrlCell } from 'src/components';
import { Routes } from 'src/constants';
import { dollarFormat } from 'src/helpers';
import { tRentLeveledProperty } from 'src/store';

const columnHelper = createColumnHelper<tRentLeveledProperty>();

export const makeRentLevelingTableColumns = (municipality_slug: string) => {
    return [
        columnHelper.accessor('address', {
            id: 'street_address',
            header: () => 'Street Address',
            cell: row => {
                const url = generatePath(Routes.PROPERTY_LEDGER_RESULT, {
                    slug: municipality_slug,
                    property_id: row.row.original.property_id,
                });
                return <PropertyUrlCell propertyDetailsUrl={url} streetAddress={row.getValue()} />;
            },
        }),
        columnHelper.accessor('block', {
            id: 'block',
            header: () => 'Block',
            cell: row => row.getValue(),
        }),
        columnHelper.accessor('lot', {
            id: 'lot',
            header: () => 'Lot',
            cell: row => row.getValue(),
        }),
        columnHelper.accessor('qualifier', {
            id: 'qualifier',
            header: () => 'Qualifier',
            cell: row => row.getValue(),
        }),
        columnHelper.accessor('number_of_units', {
            id: 'number_of_units',
            header: () => 'Number of Units',
            cell: row => row.getValue(),
        }),
        columnHelper.accessor('amount_paid', {
            id: 'amount_paid',
            header: () => 'Amount Paid',
            cell: row => `$${dollarFormat(row.getValue())}`,
        }),
        columnHelper.accessor('registration_forms_submitted', {
            id: 'registration_forms_submitted',
            header: () => 'Registration Forms Submitted',
            cell: row => row.getValue(),
        }),
        columnHelper.accessor('annual_revenue_estimate', {
            id: 'annual_revenue_estimate',
            header: () => 'Annual Revenue Estimate',
            cell: row => `$${dollarFormat(row.getValue())}`,
        }),
        columnHelper.accessor('registration_payment_compliance_rate', {
            id: 'registration_payment_compliance_rate',
            header: () => 'Registration Payment Compliance Rate',
            cell: row => row.getValue(),
        }),
    ];
};

const RentLevelingTableRow = ({
    row,
}: {
    row: Row<tRentLeveledProperty>;
    municipality_slug?: string;
}) => {
    return (
        <tr className="pointer height-medium" key={row.id}>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
        </tr>
    );
};

export const rentLevelingTableRowRenderer = (
    row: Row<tRentLeveledProperty>,
    navigate: NavigateFunction,
    municipality_slug: string | undefined
) => (
    <RentLevelingTableRow
        key={`tabbed-property-sales-row-${row.id}`}
        row={row}
        municipality_slug={municipality_slug}
    />
);
