import SpaceBetween from '@cloudscape-design/components/space-between';
import { CardsProps } from '@cloudscape-design/components/cards';
import Link from '@cloudscape-design/components/link';
import TextContent from '@cloudscape-design/components/text-content';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import moment from 'moment';

import { DOCUMENT_TYPE_NAMES, UPLOADED_DOCUMENT_TYPE_NAMES } from 'src/constants';
import { DateTimeFormats, convertPropertyDocument, formatFilesize } from 'src/helpers';
import { tPropertyDocument, tUserUploadedPropertyDocument } from 'src/types';

const PropertyDocumentCard = ({ document }: { document: tPropertyDocument }) => {
    const documentType =
        DOCUMENT_TYPE_NAMES[document.document_type] != null
            ? DOCUMENT_TYPE_NAMES[document.document_type]
            : document.document_type;

    const updatedAtMoment = moment(document.record_date).utc();
    const updatedAt = `${updatedAtMoment.format(DateTimeFormats.YYYYMMDD)}T${updatedAtMoment.format(
        DateTimeFormats.HHMMSS
    )}`;

    return (
        <>
            <SpaceBetween direction="vertical" size="s" />
            <TextContent>
                <h4>{documentType}</h4>
            </TextContent>
            {updatedAt}
        </>
    );
};

export const propertyDocumentCardDefinition: CardsProps.CardDefinition<tPropertyDocument> = {
    header: item => {
        const doc = convertPropertyDocument(item);
        return (
            <Link href={item.url} fontSize="heading-xl" target="_blank">
                {doc.name}
            </Link>
        );
    },
    sections: [
        {
            id: 'document_card',
            content: item => (
                <div id={`document-${encodeURIComponent(item.url)}`} data-testid="document-card">
                    <PropertyDocumentCard document={item} />
                </div>
            ),
        },
    ],
};

const UserUploadedDocumentCardBody = ({
    document,
}: {
    document: tUserUploadedPropertyDocument;
}) => {
    const documentType =
        UPLOADED_DOCUMENT_TYPE_NAMES[document.document_type] != null
            ? UPLOADED_DOCUMENT_TYPE_NAMES[document.document_type]
            : document.document_type;

    const updatedAtMoment = moment(document.updated_at).utc();
    const updatedAt = `${updatedAtMoment.format(DateTimeFormats.YYYYMMDD)}T${updatedAtMoment.format(
        DateTimeFormats.HHMMSS
    )}`;

    const filesize = formatFilesize(document.size_in_bytes);
    return (
        <>
            <SpaceBetween direction="vertical" size="s" />
            <TextContent>
                <h4>{documentType}</h4>
            </TextContent>
            {updatedAt}
            <br />
            {filesize}
        </>
    );
};

const DocumentInteractionButton = ({
    item,
    onSelectDelete,
    onSelectEdit,
}: {
    item: tUserUploadedPropertyDocument;
    onSelectDelete: (item: tUserUploadedPropertyDocument) => void;
    onSelectEdit: (item: tUserUploadedPropertyDocument) => void;
}) => {
    const onClick = (e: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        if (e.detail.id === 'delete') {
            onSelectDelete(item);
        }
        if (e.detail.id === 'edit') {
            onSelectEdit(item);
        }
    };
    return (
        <div className="document-card-interaction-button">
            <ButtonDropdown
                items={[
                    { id: 'download', text: 'Download Document', external: true, href: item.url },
                    { id: 'edit', text: 'Edit Document Metadata' },
                    { id: 'delete', text: 'Delete Document' },
                ]}
                variant="icon"
                onItemClick={e => onClick(e)}
            />
        </div>
    );
};

export const getUserUploadedDocumentCardDefinition = (
    showInteractionButton: boolean,
    onSelectDelete: (item: tUserUploadedPropertyDocument) => void,
    onSelectEdit: (item: tUserUploadedPropertyDocument) => void
): CardsProps.CardDefinition<tUserUploadedPropertyDocument> => {
    return {
        header: item => (
            <>
                <Link href={item.url} fontSize="heading-xl" target="_blank">
                    {item.file_name}
                </Link>{' '}
                {showInteractionButton ? (
                    <DocumentInteractionButton
                        item={item}
                        onSelectDelete={onSelectDelete}
                        onSelectEdit={onSelectEdit}
                    />
                ) : (
                    <></>
                )}
            </>
        ),
        sections: [
            {
                id: 'document_card',
                content: item => (
                    <div id={`document-${item.hash_digest}`} data-testid="document-card">
                        <UserUploadedDocumentCardBody document={item} />
                    </div>
                ),
            },
        ],
    };
};
