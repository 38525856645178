export const formatTimestamp = (timestamp: number | bigint): string => {
    const date = new Date(Number(timestamp) * 1000);
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    }).format(date);
};
