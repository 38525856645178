import './SearchAdditionLine.scss';
import { FC } from 'react';
import { MainInput } from 'src/components';
import { OccupancyStatus } from 'src/constants';
import { tSearchData, tInfo } from 'src/types';
import { MainInputDropdown } from '../Inputs/MainInputDropdown';
import { tInputErrors } from 'src/helpers';

interface IProps extends tSearchData {
    onChangeInfo: ({ searchFieldName, searchInputValue }: tInfo) => void;
    inputErrors: tInputErrors;
}
export const SearchAdditionLine: FC<IProps> = ({
    block,
    lot,
    qualifier,
    occupancy,
    onChangeInfo,
    inputErrors,
}) => {
    // const inputs = [
    //     { placeholder: 'Start type name', label: 'Owner Name', key: 'owner_name' },
    //     { placeholder: 'Enter block number', label: 'Block', key: 'block' },
    //     { placeholder: 'Enter lot number', label: 'Lot', key: 'lot' },
    //     { placeholder: 'Enter a qualifier', label: 'Qualifier', key: 'qualifier' },
    //     { placeholder: 'Enter an ID', label: 'Tax Account ID', key: 'tax' },
    // ];
    return (
        <div className="search-line">
            <div className="search-line__input-wrapper">
                <MainInput
                    placeholder="Enter block number"
                    label="Block"
                    value={block}
                    onChangeValue={(inputValue: string) =>
                        onChangeInfo({ searchFieldName: 'block', searchInputValue: inputValue })
                    }
                    messageError={inputErrors.block}
                />
            </div>
            <MainInput
                placeholder="Enter lot number"
                label="Lot"
                value={lot}
                onChangeValue={(inputValue: string) =>
                    onChangeInfo({ searchFieldName: 'lot', searchInputValue: inputValue })
                }
            />
            <MainInput
                placeholder="Enter a qualifier"
                label="Qualifier"
                value={qualifier}
                onChangeValue={(inputValue: string) =>
                    onChangeInfo({ searchFieldName: 'qualifier', searchInputValue: inputValue })
                }
            />
            <MainInputDropdown
                items={[
                    OccupancyStatus.ALL,
                    OccupancyStatus.VACANT_AND_ABANDONED,
                    OccupancyStatus.OCCUPIED,
                ]}
                value={occupancy}
                onSelect={(inputValue: string) =>
                    onChangeInfo({ searchFieldName: 'occupancy', searchInputValue: inputValue })
                }
                placeholder="Select an occupancy"
                label="Occupancy"
                className="occupancy-select"
                disableTyping={true}
            />
        </div>
    );
};
